<template>
  <h2
    class="big-text"
    :color="color"
    :bgcolor="bgcolor"
    :position="position"
  >
    {{ title }}
  </h2>
</template>

<script>
export default {
  name: 'BigText',
  props: {
    color: String,
    bgcolor: String,
    title: String,
    position: String
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

h2.big-text {
  max-width: 100% !important;
  font-weight: 900;
  //color: transparent;
  color: white;
  text-transform: uppercase;
  font-size: 60px;
  //-webkit-text-fill-color: transparent;
  //-webkit-text-stroke-color: #ffffff;
  // -webkit-text-stroke-width: 0.05rem;
  position: absolute;
  top: 0;
  margin: 0;
  line-height: 85px;
  text-align: left;
  left: -7px;
  padding-top: 10px;

  @include media-breakpoint-up(md) {
    font-size: 110px;
    line-height: 100px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 150px;
    line-height: 140px;
  }
}

h2.big-text[color="green"] {
  //-webkit-text-stroke-color: var(--green-color);
  text-shadow: -1px -1px 0 var(--green-color), 1px -1px 0 var(--green-color), -1px 1px 0 var(--green-color), 1px 1px 0 var(--green-color);
}

h2.big-text[color="white"] {
  //-webkit-text-stroke-color: #ffffff;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}

h2.big-text[color="yellow"] {
  //-webkit-text-stroke-color: #ffffff;
  text-shadow: -1px -1px 0 var(--yellow-color), 1px -1px 0 var(--yellow-color), -1px 1px 0 var(--yellow-color), 1px 1px 0 var(--yellow-color);
}

h2.big-text[bgcolor="yellow"] {
  color: var(--dark-yellow-color);
}

h2.big-text[bgcolor="white"] {
  color: #fff;
}

h2.big-text[bgcolor="red"] {
  color: var(--red-color);
}

h2.big-text[bgcolor="green"] {
  color: var(--green-color);
}

h2.big-text[bgcolor="purple"] {
  color: var(--purple-color);
}

h2.big-text[position="right"] {
  text-align: right;
  right: -20px;

  @include media-breakpoint-up(md) {
    right: -45px;
  }
}
</style>
